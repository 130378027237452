<template>
  <v-container>
    <!-- Header -->
    <v-container>
      <v-card color="secondary">
        <div class="text-h3 white--text pa-4 d-flex justify-center">About</div>
      </v-card>
    </v-container>

    <v-container>
      <v-row>
        <v-col>
          <p>
            Hi! My name is Michael, and I designed this app when I was moving to Japan. I was
            frustrated by most of the language learning apps I found on the market and wanted to
            make something that could help me learn the way I wanted to learn. So I made this, and
            I've been using it every day. It's helped me a ton, and I hope it can help you too!
            Here's how it works:
          </p>
          <h1>Goal</h1>
          <p>
            Starfish is an app designed to aid learning acquisition by building vocabulary through
            flashcards.
          </p>

          <h1>Features</h1>
          <li>Flashcard production.</li>
          <p>
            Making flashcards is normally a ton of work. Starfish makes it easy by searching the
            internet for images and examples sentences (with audio!) for you and letting you choose.
          </p>
          <li>SRS</li>
          <p>
            Starfish uses SRS (Simple Repetition System) to optimize learning. We learn best when we
            study something when we're just on the verge of forgetting it. Every time you remember a
            word correctly, Starfish will creater a longer gap until the next study time. Forget it
            though, and it will appear again the next day.
          </p>
          <li>Context Rich</li>
          <p>
            Learning words isolated from context is an ineffective learning strategy. We learn best
            by creating associations and placing things within contexts. This is why Starfish lets
            you pick your own images and example sentences with every word, so you can experience
            words the way a native learner would. In addition, you are encouraged to create your own
            example sentence as an optional additional step of mastery.
          </p>
          <li>Minimal English</li>
          <p>
            English translations are available, but they only appear when hovering over a
            word/sentence. Ideally, you barely need to use them. Learn languages directly from the
            source (images and native sentences), the way children do!
          </p>
          <li>Languages Available</li>
          <p>
            Right now, the app is best designed for Japanese. Chinese and Turkish are also
            available. If you have another language you want to learn, let me know, and I can add
            it.
          </p>

          <h1>How to Use</h1>
          <p>
            First go to "Settings", where you can set the language you want to learn and then set
            your level and any filters you want. I recommend starting at the first level and just
            learning the words as they appear. Evey word will go through 3 stages:
          </p>
          <p>
            1. Learn. This is where you create your flashcards. If you already know a word, just hit
            "Already Mastered" to skip it. Otherwise, select an example sentence and picture for
            your flashcard. If you don't like the images that show up, there is a search bar so you
            are free to search anything. Hitting "Refresh" with the same term will get new results.
          </p>
          <p>
            2. Recall. The app will prompt you when you have words to study here. You will see the
            picture and blanked sentence. Try to remember the word. When you're ready, "flip" the
            card and mark yourself right or wrong.
          </p>
          <p>
            3. Production. Once you get a word correct 5 times in a row, it will move to production.
            This time, you get the picture, and the translation of the sentence in English, and you
            have to produce the original sentence. You can try writing it in the box, and then flip
            to see how close you are.
          </p>
          <p>
            When you get the production correct 5 times in a row, the word is considered mastered
            and will stop appearing. I recommend creating your own example sentence as a final act
            of mastery!
          </p>

          <p>
            Additionally, there is a "Play" page. This is for fun and is a low mental requirement
            form of studying. It just shows you a word and 4 pictures, and you pick the picture.
            Good supplemental tool when you want to study a little but you're also tired.
          </p>
          <h1>My Suggestion for Daily Practice</h1>
          <p>
            Go to "Learn" and create 5 flashcards for new words every day. Then, study every word
            you're prompted in the "Recall" and "Production" pages. Spend 10-20 minutes per day,
            that's it, and you can easily master ~1000+ words in a year.
          </p>

          <h1>Tips 'n Tricks</h1>
          <p>I've integrated a couple cool tools you might find helpful</p>
          <ol>
            <li>Dictionary Lookup.</li>
            <p>
              Highlight any text on the screen, and it will look up the word in the dictionary and
              show the results at the bottom of the screen. This is especially useful in Japanese
              when you see kanji in an example sentence that you don't know.
            </p>
            <li>Hover Lookup.</li>
            <p>Hover over your word or sentence to lookup translation or pronounciation.</p>
            <li>External Links.</li>
            <p>
              Each word is connected to another external dictionary that you can click to go see
              more example sentences, conjugations, pronounciations, etc.
            </p>
            <li>Youtube examples.</li>
            <p>
              On the "Production" page, after you flip a card, you'll see a little widget that loads
              examples of the word being used in Youtube. You can use this to listen to lots of
              various real-life examples of the word.
            </p>
          </ol>
          <hr />
          <br />
          <p>
            Thanks for using my app! I am actively developing and trying to improve this, so feel
            very free to contact me with any suggestions, questions, or bug reports at
            muebeletech@gmail.com.
          </p>
          <p>- Michael</p></v-col
        >
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  components: {},

  data: function () {
    return {
      users: [],
      userId: {},
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
  },

  methods: {},
  mounted() {},
}
</script>
